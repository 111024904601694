<template>
  <div class="admin-settings-trnaslations">
    <b-card class="cms-pages-admin" :title="pageTitle">
      <b-row>
        <b-col cols="12">
          <validation-observer ref="SubscriptionPackageForm">
            <b-form @submit.prevent="submitForm">
              <b-row class="d-flex justify-content-center">
                <b-col cols="12" md="5">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package Name')"
                        label-for="package_name"
                      >
                        <validation-provider #default="{ errors }" :name="$t('Package Name')" rules="required">
                          <b-form-input 
                            id="package_name"
                            v-model="formData.package_name" 
                            :state="errors.length > 0 ? false : null" 
                            :placeholder="$t('Package Name')" 
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Default Package Amount')"
                        label-for="package_amount"
                      >
                        <validation-provider #default="{ errors }" :name="$t('Default Package Amount')" rules="required">
                          <b-form-input 
                            id="package_amount"
                            v-model="formData.package_amount" 
                            :state="errors.length > 0 ? false : null" 
                            :placeholder="$t('Default  Package Amount')" 
                            type="number"
                            :min="0"
                            :step="0.001"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package Image')"
                        label-for="package_image"
                      >
                        <b-img 
                          v-if="!isEmpty(editData) && !isEmpty(editData.image)"
                          :src="editData.image" 
                          class="mt-2 float-right"
                          width="150"
                          alt="basic svg img" 
                        />
                        <b-form-file
                          id="package_image"
                          v-model="image"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <label>{{ $t('Package Feature(s)') }}</label>
                      <b-row v-for="(feature, fk) in features" :key="`features_${fk}`" class="mb-1">
                        <b-col cols="12" md="10">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                v-model="featuresEnabled[fk]"
                              />
                            </b-input-group-prepend>
                            <b-form-input 
                              v-model="features[fk]" 
                              :placeholder="$t('Feature Name')+fk" 
                            />
                          </b-input-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-button size="sm" variant="outline-danger" @click="removeFeature(fk)">X</b-button>
                        </b-col>
                      </b-row>
                      <b-col cols="12" class="d-flex justify-content-end">
                        <b-button variant="outline-primary" size="sm" @click="addNewFeature">+ Add new</b-button>
                      </b-col>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package Description')"
                        label-for="description"
                      >
                        <validation-provider #default="{ errors }" :name="$t('Package Description')">
                          <b-form-textarea
                            id="description"
                            v-model="formData.description" 
                            :state="errors.length > 0 ? false : null" 
                            :placeholder="$t('Package Description')" 
                            rows="3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package tenure type')"
                        label-for="tenure_type"
                      >
                        <validation-provider #default="{ errors }" :name="$t('Package tenure type')" rules="required">
                          <b-form-select
                            id="tenure_type"
                            v-model="formData.tenure_type"
                            :options="tenureTypeOpts"
                            :state="errors.length > 0 ? false : null" 
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package tenure')"
                        label-for="tenure"
                      >
                        <validation-provider #default="{ errors }" :name="$t('Package tenure')" rules="required|integer">
                          <b-form-input 
                            id="tenure"
                            v-model="formData.tenure" 
                            :state="errors.length > 0 ? false : null" 
                            :placeholder="$t('Package tenure')" 
                            type="number"
                            :min="1"
                            :step="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="my-2 d-flex justify-content-start">
                      <b-form-checkbox
                        v-model="formData.is_custom"
                        name="check-button"
                        switch
                        inline
                        class="mr-3"
                      >
                        {{ $t('Custom') }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-if="formData.is_custom" 
                        v-model="formData.is_public"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('Show on form') }}
                      </b-form-checkbox>
                    </b-col>
                    <template v-if="this.orderAllowPackage.includes(this.packageFor)">
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Allowed Orders')"
                          label-for="allowed_orders"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Allowed Orders')" rules="required|integer">
                            <b-form-input 
                              id="allowed_orders"
                              v-model="formData.allowed_orders" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Allowed Orders')" 
                              type="number"
                              :min="1"
                              :step="1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Exceed Amount (Per Order)')"
                          label-for="exceed_amount_per_order"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Exceed Amount (Per Order)')" rules="required">
                            <b-form-input 
                              id="exceed_amount_per_order"
                              v-model="formData.exceed_amount_per_order" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Exceed Amount (Per Order)')" 
                              type="number"
                              :min="0"
                              :step="0.001"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </template>
                    
                    <template v-if="this.aiUsageAllowPackage.includes(this.packageFor)">
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Allowed Words')"
                          label-for="allowed_words"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Allowed Words')" rules="required|integer">
                            <b-form-input 
                              id="allowed_words"
                              v-model="formData.allowed_words" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Allowed Words')" 
                              type="number"
                              :min="0"
                              :step="1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Allowed Images')"
                          label-for="allowed_images"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Allowed Images')" rules="required|integer">
                            <b-form-input 
                              id="allowed_images"
                              v-model="formData.allowed_images" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Allowed Images')" 
                              type="number"
                              :min="0"
                              :step="1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Allowed Speech to Text')"
                          label-for="allowed_s2t"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Allowed Speech to Text')" rules="required|integer">
                            <b-form-input 
                              id="allowed_s2t"
                              v-model="formData.allowed_s2t" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Allowed Speech to Text')" 
                              type="number"
                              :min="0"
                              :step="1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Allowed Text to Speech')"
                          label-for="allowed_t2s"
                        >
                          <validation-provider #default="{ errors }" :name="$t('Allowed Text to Speech')" rules="required|integer">
                            <b-form-input 
                              id="allowed_t2s"
                              v-model="formData.allowed_t2s" 
                              :state="errors.length > 0 ? false : null" 
                              :placeholder="$t('Allowed Text to Speech')" 
                              type="number"
                              :min="0"
                              :step="1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </template>
                    <b-col cols="12" class="mt-4">
                      <b-row>
                        <b-col cols="12">
                          <label for="">Add Country Wise Different Prices (in $)</label>
                          <b-table class="mb-4" striped hover :items="tableItems" :fields="tableFields">
                            <template #cell(action)="data">
                                <img :src="$helpers.getIcons('delete')" class="cp" title="Remove" width="28" @click="removeCountryPrice(data.item.country_id)" />
                            </template>
                          </b-table>
                        </b-col>
                        <b-col cols="4" class="mb-2">
                          <v-select
                            v-model="country"
                            :options="countriesOpts"
                            :clearable="false"
                            multiple
                            placeholder="Select country"
                          />
                        </b-col>
                        <b-col cols="3">
                          <b-input-group>
                            <b-form-input size="sm" v-model="price" type="number" :min="1" :step="0.01" placeholder="Price" />
                          </b-input-group>
                        </b-col>
                        <b-col cols="3">
                          <b-input-group>
                            <b-form-input size="sm" v-model="extra" type="number" :min="0" :step="0.01" placeholder="Extra" />
                          </b-input-group>
                        </b-col>
                        <b-col cols="2">
                          <b-button size="sm" variant="outline-primary" @click="addCountryPrice" :disabled="isEmpty(price) || !isNumeric(price) || isEmpty(extra) || !isNumeric(extra) || isEmpty(country)">Add</b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12">
                      <b-button variant="success" type="submit">
                        {{ $t('Submit') }}
                      </b-button>
                      <b-button @click="cancelForm" variant="danger" type="button" class="ml-2">
                        {{ $t('Cancel') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer
} from '@validations'
import {
  isEmpty, isNumeric
} from '@/utilities'
import vSelect from 'vue-select'
const tableFields = [
  {label: 'Country', key: 'country'},
  {label: 'Price ($)', key: 'price'},
  {label: 'Extra', key: 'extra'},
  {label: 'Action', key: 'action'},
]
export default {
  name: "SubscriptionPackageForm",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // VueJsonEditor,
  },
  data() {
    return {
      tableFields,
      image: null,
      featuresEnabled: [],
      features: [],
      tenureTypeOpts: [
        {
          text: 'Select an Option',
          value: '',
        },
        {
          text: 'Month',
          value: 'MONTH',
        },
        {
          text: 'Year',
          value: 'YEAR',
        },
        {
          text: 'Lifetime',
          value: 'LIFETIME',
        },
      ],
      formData: {
        package_name: '',
        package_amount: 0,
        description: '',
        tenure: '',
        tenure_type: '',
        allowed_orders: '',
        allowed_words: '',
        allowed_images: '',
        allowed_s2t: '',
        allowed_t2s: '',
        exceed_amount_per_order: '',
        status: 1,
        is_custom: false,
        is_public: false,
      },
      prices: [],
      country: [],
      price: null,
      extra: null,
      orderAllowPackage: [
        'STORE', 'PROVIDER'
      ],
      aiUsageAllowPackage: [
        'USER', 'STORE', 'PROVIDER'
      ],
      required, integer
    }
  },
  computed: {
    ...mapGetters('subscriptionPlan', [
      'getSubscription',
      'isSubscriptionLoaded',
      'isSubscriptionCountryLoaded',
      'countries',
      'findCountryPrices',
    ]),
    isEdit: {
      get(){
        if(!isEmpty(this.$route.params.uuid)){
          return true
        }
        return false
      }
    },
    pageTitle: {
      get(){
        if(this.isEdit){
          return this.$t("Update a Subscription")
        }
        return this.$t("Add a new Subscription")
      }
    },
    editData: {
      get(){
        if(!this.isEdit) return false
        return this.getSubscription(this.$route.params.uuid)
      }
    },
    packageFor: {
      get(){
        return this.$route.params.package_for.toUpperCase()
      }
    },
    countriesOpts: {
      get(){
        const currentAddedCountries = this.prices.map(i => i.country_id)
        return this.countries.filter(i => !currentAddedCountries.some(j => j == i.id)).map(i => ({
          value: i.id,
          label: `${i.country_code} - ${i.country_name}`
        }))
      }
    },
    tableItems: {
      get(){
        return this.prices.map(i => {
          const country = this.countries.find(j => j.id == i.country_id)
          return {
            country_id: i.country_id,
            country: `${country.country_code} - ${country.country_name}`,
            price: i.price,
            extra: i.extra,
          }
        })
      }
    }
  },
  methods: {
    isEmpty, isNumeric,
    ...mapActions('subscriptionPlan', [
      'createSubscriptionPackage',
      'updateSubscriptionPackage',
      'getSubscriptionPackages',
      'getCountries',
      'getCountryPrices',
    ]),
    submitForm(){
      this.$refs.SubscriptionPackageForm.validate().then(success => {
        if (success) {
          let updateFn = this.createSubscriptionPackage
          showLoader()
          if(!this.formData.is_custom) this.formData.is_public = false;

          let formData = this.formData
          if(this.isEdit){
            updateFn = this.updateSubscriptionPackage
            formData.uuid = this.$route.params.uuid
          }else{
            formData.package_for = this.packageFor
          }
          const formDataObj = new FormData
          const booleanVals = ['is_public', 'is_custom']
          Object.keys(formData).forEach(i => {
            if(booleanVals.some(b => b === i)){
              formDataObj.append(i, formData[i] ? 1 : 0)
            }else{
              formDataObj.append(i, formData[i])
            }
          })
          if(!isEmpty(this.prices)){
            this.prices.forEach(p => {
              formDataObj.append(`prices[${p.country_id}]`, p.price);
              formDataObj.append(`extras[${p.country_id}]`, p.extra);
            })
          }
          const features = this.features.map((i, idx) => {
            return {
              label: i,
              enabled: this.featuresEnabled[idx]
            }
          })
          formDataObj.append('features', JSON.stringify(features))
          if(!isEmpty(this.image)){
            formDataObj.append('image', this.image)
          }
          updateFn(formDataObj)
            .then(({ data }) => {
              if (data) {
                if (data.statusCode === '200') {
                  showSuccessNotification(this, this.isEdit ? "Subscription Package updated successfully" : "Subscription Package updated successfully")
                  this.cancelForm()
                }
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    updateFormData(){
      if(!isEmpty(this.editData) && !isEmpty(this.$route.params.uuid)){
        Object.keys(this.formData).forEach(i => {
          if(!isEmpty(this.editData[i])){
            this.formData[i] = this.editData[i]
          }
        })
        if(!isEmpty(this.editData.features)){
          this.features = this.editData.features.map(i => i.label)
          this.featuresEnabled = this.editData.features.map(i => i.enabled)
        }
      }
      if(this.features.length <= 0){
        this.addNewFeature()
      }
    },
    updatePricing(){
      if(this.isEdit){
        const countryPrices = this.findCountryPrices(this.$route.params.uuid)
        countryPrices.forEach(cp => {
          this.prices.push({
            country_id: cp.country_id,
            price: cp.price,
            extra: cp.extra,
          })
        })
      }
      if(this.features.length <= 0){
        this.addNewFeature()
      }
    },
    cancelForm(){
      const name = `admin-subscription-packages-${this.$route.params.package_for}`
      this.$router.push({name})
    },
    addNewFeature(){
      this.features.push('')
      this.featuresEnabled.push(true)
    },
    removeFeature(fk){
      this.features.splice(fk, 1)
      this.featuresEnabled.splice(fk, 1)
    },
    addCountryPrice(){
      if(!isEmpty(this.country) && !isEmpty(this.price) && isNumeric(this.price) && !isEmpty(this.extra) && isNumeric(this.extra)){
        const selectedCountries = this.country.map(i => i.value)
        selectedCountries.forEach(c => {
          const countryPrice = this.prices.find(i => i.country_id == c)
          if(isEmpty(countryPrice)){
            this.prices.push({
              country_id: c,
              price: parseFloat(this.price),
              extra: parseFloat(this.extra),
            })
          }
        })
      }
      this.country = []
      this.price = null
      this.extra = null
    },
    removeCountryPrice(country_id){
      if(!isEmpty(country_id)){
        this.prices = this.prices.filter(i => i.country_id != country_id)
      }
    }
  },
  mounted() {
    if(!this.isSubscriptionLoaded){
      showLoader()
      this.getSubscriptionPackages()
        .then(res => {
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    }
    if(this.isEdit){
      this.getCountryPrices(this.$route.params.uuid).then(r => {
        this.updatePricing()
      })
    }
    this.updateFormData()

    if(!this.isSubscriptionCountryLoaded){
      this.getCountries()
    }
  },
  watch: {
    editData: {
      handler(nv, ov){
        this.updateFormData()
      }
    }
  }
}
</script>
  